<!-- 节能减排 -->
<template>
  <div class="mainbox">
    <div class="left">
      <div class="modulebox">
        <div class="f_row">
          <div class="f_column">
            <div class="title"><span>全市企业占比分析</span></div>
            <div class="content enterprisebox">
              <div class="left">
                <div class="img"><img src="../../assets/images/r1.png"></div>
                <div class="text">
                  <p>企业总数</p>
                  <p><b>{{CompanyDevCount.company}}</b>家</p>
                </div>
              </div>
              <div class="right">
                <div class="top">
                  <div ref="pie1" class="echarts"></div>
                </div>
                <div class="bottom">
                  <div class="li">
                    <div class="img"><img src="../../assets/images/r2.png"></div>
                    <div class="text">
                      <p>重点排放企业</p>
                      <p><b>{{CompanyDevCount.dischargeCompany}}</b>家</p>
                    </div>
                  </div>
                  <div class="li">
                    <div class="img"><img src="../../assets/images/r3.png"></div>
                    <div class="text">
                      <p>控排企业</p>
                      <p><b>{{CompanyDevCount.payCompany}}</b>家</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="f_column">
            <div class="enterpriselist">
              <h2>企业名称</h2>
              <p style="text-align: center;margin-top: 2rem;" v-if="CompanyDevCount.companyList.length==0">暂无数据</p>
              <div class="enterpBOX" >
                <vue-seamless-scroll :data="CompanyDevCount.companyList" :class-option="{limitMoveNum: 7,direction: 1,step: 0.4,}">
                  <ul>
                    <li v-for="(item,index) in CompanyDevCount.companyList" :key="index">{{item.name}}</li>
                  </ul>
                </vue-seamless-scroll>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="f_column">
          <div class="title"><span>各地区碳配额占比分析</span></div>
          <div class="content region">
            <div class="left">
              <div ref="bar1" class="echarts"></div>
            </div>
            <div class="right">
              <div ref="pie2" class="echarts"></div>
              <div class="text">
                <p>全市总指标：<b>{{quotaProportion.total}}</b>万吨</p>
                <p>全市已消耗：<b>{{quotaProportion.used}}</b>万吨</p>
              </div>
            </div>
          </div>
        </div>
        <div class="f_column">
          <div class="title"><span>各行业碳配额占比分析</span></div>
          <div class="content industry">
            <div class="left">
              <div ref="pie3" class="echarts"></div>
              <div class="text">
                <p>总配额量：<b>{{tradeProportion.total}}</b>万吨</p>
                <p>已消耗配额：<b>{{tradeProportion.used}}</b>万吨</p>
              </div>
            </div>
            <div class="right">
              <div ref="bar2" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="f_column">
          <div class="title"><span>全市绿证占比分析</span></div>
          <div class="content greencard">
            <div class="left">
              <div class="li">
                <p><img src="../../assets/images/r4.png">CCER核证项目总数：</p>
                <p><span><b>{{Certificate.ccerTotal}}</b>个</span><span>同比：<b>{{Certificate.ccerProValueTB}}%<i class="el-icon-top"></i></b></span></p>
              </div>
              <div class="li">
                <p><img src="../../assets/images/r5.png">核证项目CCER年产值：</p>
                <p><span><b>{{Certificate.ccerProValue}}</b>吨</span><span>同比：<b>{{Certificate.ccerTotalTB}}%<i class="el-icon-top"></i></b></span></p>
              </div>
            </div>
            <div class="right">
              <div ref="pie4" class="echarts"></div>
            </div>
          </div>
        </div>
        <div class="f_column">
          <div class="title"><span>全市碳资产管理企业占比分析</span></div>
          <div class="content carbonassets">
            <div class="left">
              <div ref="pie5" class="echarts"></div>
            </div>
            <div class="right">
              <div ref="line1" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="modulebox">
        <div class="title"><span>重点能耗企业控排指标排名</span></div>
        <div class="content ranking">
          <div class="left">
            <div class="numbers">
              <span>控排指标</span>
              <div class="box">
                <label v-for="(e,i) in pointCompanyTarget.targetTotalArr " :key="i" >{{e}}</label>
              </div>
              <span>万吨</span>
            </div>
            <div class="bottom">
              <div class="li">
                <p>年度能源消耗总量</p>
                <p><img src="../../assets/images/r6.png"><b>{{pointCompanyTarget.energyTotal}}</b>万吨标煤</p>
              </div>
              <div class="li">
                <p>年度碳排放总量</p>
                <p><img src="../../assets/images/r7.png"><b>{{pointCompanyTarget.dischargeTotal}}</b>万吨</p>
              </div>
            </div>
          </div>
          <div class="right">
            <table>
              <thead>
                <tr><td></td><td>能源消耗总量</td><td>控排指标</td></tr>
              </thead>
              <tbody>
                <!-- <vue-seamless-scroll :data="pointCompanyTarget.companyList" :class-option="{limitMoveNum: 7,direction: 1,step: 0.4,}"> -->
                
                  <tr v-for="(e,i) in pointCompanyTarget.companyList" :key="i" >
                    <td>{{e.companyName}}</td><td><b>{{e.data}}</b>万吨</td><td><b>{{e.target}}</b>万吨</td>
                  </tr>
                <!-- </vue-seamless-scroll> -->
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>碳达峰碳中和战略方向</span></div>
        <div class="content">
          <div ref="bar3" class="echarts"></div>
        </div>
      </div>
      <div class="modulebox">
        <div class="f_column">
          <div class="titlebox">
            <div class="title"><span>各地区近年来碳市场单价分析</span></div>
            <!-- <div class="select">
              地区选择
              <el-select v-model="option" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="content">
            <div ref="line2" class="echarts"></div>
          </div>
        </div>
        <div class="f_column">
          <div class="title"><span>各试点地区碳市场交易占比</span></div>
          <div class="content transaction">
            <div class="left">
              <div ref="pie6" class="echarts"></div>
            </div>
            <div class="right">
              <div ref="pie7" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import api from '@/apis/PowerSaving/index'
let echarts = require('echarts');
import common from '@/components/monitorCommon/common'
export default {
  components:{
    vueSeamlessScroll,
  },
  data() {
    return {
      CompanyDevCount:{
        company: 1277,
        companyList: [],
        dischargeCompany: 0,
        payCompany: 0,
        pointDischargeCompany: 0,
        quotaCompany: 0,
      },
      //全市绿证占比分析
      Certificate:{
        PV: 0,
        ccerProValue: 0,
        ccerProValueLast: 0,
        ccerTotal: 0,
        ccerTotalLast: 0,
        forestry: 0,
        waterPower: 0,
        ccerProValueTB:0,
        ccerTotalTB:0
      },
      //各地区碳配额占比分析
      quotaProportion:{
        total:0,
        used:0,
      },
      //各行业碳配额占比分析
      tradeProportion:{
        total:0,
        used:0,
      },

      //重点能耗企业控排指标排名
      pointCompanyTarget:{
        companyList:[],
        dischargeTotal:0,
        energyTotal:0,
        targetTotal:0,//控排指标
        targetTotalArr:[0]
      },

      option:'北京',
      options: [
        { value: '北京', label: '北京'}, 
        { value: '上海', label: '上海'}, 
        { value: '广东', label: '广东'}, 
        { value: '天津', label: '天津'}, 
        { value: '深圳', label: '深圳'}, 
        { value: '湖北', label: '湖北'}, 
        { value: '重庆', label: '重庆'}, 
        { value: '福建', label: '福建'}, 

      ],

      data1:[
        { value: 0, name: '北京' },
        // { value: 1000, name: '上海' },
        // { value: 9000, name: '广东' },
        // { value: 8000, name: '天津' },
        // { value: 6000, name: '深圳' },
        // { value: 6000, name: '湖北' },
        // { value: 6000, name: '重庆' },
        // { value: 6000, name: '福建' },
      ],
      data2:[
        { value: 0, name: '北京' },
        // { value: 4000, name: '北京' },
        // { value: 3600, name: '福建' },
        // { value: 2200, name: '上海' },
        // { value: 1900, name: '广东' },
      ],

      bar2Chart:null,
      bar2Index:-1,
      bar2Interval:null,


  
    };
  },

  methods:{
    myfun(num=0,num2=0) {
      var myChart = echarts.init(this.$refs.pie1);
      var data = [
        { name: "已纳入配额分配企业", value: num},//value为百分比（取整）,gdp为值
        { name: "已完成清缴企业", value: num2},
      ];
      var titleArr = [];
      var seriesArr = [];
      var colors = [
        ["#eb6098", "#2b3473"],["#b486f3", "#2b3473"],
      ];
      data.forEach( (item, index) =>{
        titleArr.push({
          text: item.name,
          left: index * 50 + 20 +'%',
          top: '65%',textAlign: "center",
          textStyle: {
            fontWeight: "normal",fontSize: 20,color:'#ccd0f2',textAlign: "center"},
          }
        );
        seriesArr.push({
          type: "pie",
          clockwise: false,
          radius: ['42%', '50%'],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          center: [index * 50 + 20 +'%', '30%'],
          data: [
            {
              value: 100 - item.value,
              name: "invisible",
              itemStyle: {
                normal: {
                  color: colors[index][1],
                },
                emphasis: {
                  color: colors[index][1],
                },
              },
            },
            {
              value: item.value,
              label: {
                normal: {
                  formatter: function (params) {
                    return params.value + "%";
                  },
                  position: "center",
                  show: true,
                  textStyle: {
                    fontSize: 28,
                    fontWeight: "bold",
                    color:colors[index][0]
                  },
                },
              },
              itemStyle: {
                normal: {
                    color: colors[index][0],
                    borderWidth:5,
                    borderColor:colors[index][0],
                },
                emphasis: {
                    color: colors[index][0],
                }
              }
            },
          ],
        });
      });
      var option = {
        title: titleArr,
        series: seriesArr,
      };
      myChart.setOption(option);
    },
    mypie(name,data,color) {
			let pieChart = echarts.init(this.$refs[name])

      

			pieChart.setOption({
				series: [
          {
            type: 'pie',
            radius: ['58%', '70%'],
            hoverAnimation:false,
            itemStyle:{
              normal:{
                label:{show:false},labelLine:{show:false},
              }
            },
            data: [
              { value: data,
                label:{
                  show:true, position:'center',textStyle:{fontSize:40,color:color,fontWeight:600},
                  formatter:function(params){
                    return params.value +'%'
                  }
                },
                itemStyle:{
                  borderWidth:5,borderColor:color,color:color,
                }
              },
              { value: 100-data,
                itemStyle:{
                  color:'#2b3473'
                }
              },
            ]
          }
        ]
			})
		},
    bar1(area=[],total=[],used=[]){
      
      let bar1Chart = echarts.init(this.$refs.bar1);
      common.moveEcharts(area.length,bar1Chart)

			bar1Chart.setOption({

        color:['#b486f3','#00ccff'],
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
            left: '14%',right: '6%', bottom: '4%', top:'8%'
        },
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 18, itemGap: 40,right: 10,
            data: ['已消耗', '总指标'],
            textStyle: { color: "#fff",fontSize: 20 }
        },
        xAxis: {
          type: 'value',show:false
        },
        yAxis: {
          minInterval:1,
          type: 'category', axisLine:{ show:false}, axisTick:{ show:false},
          axisLabel: { color:'#fff',fontSize: 20},
          data: area
        },
        series: [
          {
            barWidth: '35%',
          label:{show:true,position: 'right',fontSize:20,fontWeight:600,color:'#fff',offset:[0,2]},
            // stack: 'total', 
            data:total,
            name: "总指标",
            type: "bar",
          },
          {
            barGap: "-100%", /*这里设置包含关系，只需要这一句话*/
            barWidth: '35%',
            label:{show:true,position: 'right',fontSize:20,fontWeight:600,color:'#00ccff'},
            data: used,
            name: "已消耗",
            type: "bar"
          }
        ]

			})
    },
    bar2(industry=[],total=[],used=[]){
      let bar2Chart = echarts.init(this.$refs.bar2);
      common.moveEcharts(industry.length,bar2Chart)
			bar2Chart.setOption({
        color:['#ffff00','#00ffcc'],
        grid: {
            left: '5%',right: '5%', bottom: '30%', top:'18%'
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          }
        },
        legend: {
            right: 40,top:0,
            textStyle: { color: "#fff",fontSize: 20 },itemGap: 30
        },
        title: {
          subtext: '单位：万吨',left: '5%',
          subtextStyle:{ fontSize: 20, color:'#ccd0f2'}
        },
        xAxis: {
            type: 'category', 
            data: industry,
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,interval: 0, rotate: 35}
        },
        yAxis: {
          minInterval:1,
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            type: 'bar',barWidth: '16%', name:'总配额',
            data: total
          },
          {
            type: 'bar',barWidth: '16%', name:'消耗配额',
            data: used
          }
        ]
			})
    },
    pie4(regenerate=0,forestry=0,waterPower=0,pv=0){
      let pie4Chart = echarts.init(this.$refs.pie4)
			pie4Chart.setOption({
        color:['#00ffcc','#00ccff','#b486f3','#ffff00'],
        title:{
          text:'全市CCER核认证企业分类占比', left:'center',
          textStyle:{ fontSize:22,color:'#00ffcc', fontWeight:500}
        },
				series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            center:['50%','55%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: true, color:'#ccd0f2',fontSize: 20,
              formatter: '{b}\n{v|{d} %}',
              rich: {
                v: {
                  fontSize: 20,lineHeight:30,fontWeight:600,
                  color: '#fff'
                }
              }
            },
            labelLine:{
              length:20,length2:40,
              lineStyle:{ color:'#ccd0f2',width:2}
            },
            data: [
              { value: regenerate, name: '再生生物' },
              { value: forestry, name: '林业碳汇' },
              { value: waterPower, name: '水电' },
              { value: pv, name: '光伏' },
            ]
          }
        ]
			})
    },
    pie5(newEnergy=0,energy=0){
      let pie5Chart = echarts.init(this.$refs.pie5)
      
			pie5Chart.setOption({
        color:['#00ffcc','#b486f3'],
				series: [
          {
            type: 'pie',
            radius: ['0', '50%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: true, color:'#ccd0f2',fontSize: 20,
              formatter: '{b}\n{v|{d} %}',
              rich: {
                v: {
                  fontSize: 20,lineHeight:30,fontWeight:600,
                  color: '#fff'
                }
              }
            },
            labelLine:{
              length:10,length2:20,
              lineStyle:{ color:'#ccd0f2',width:2}
            },
            data: [
              { value: newEnergy, name: '新能源' },
              { value: energy, name: '非新能源' },
            ]
          }
        ]
			})
    },
    line1(data=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]){
      let line1Chart = echarts.init(this.$refs.line1);
      common.moveEcharts(data.length,line1Chart)
			line1Chart.setOption({
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
        },
        color:['#00ffcc'],
        grid: {
            left: '6%',right: '4%', bottom: '18%', top:'12%'
        },
        title: {
          subtext: '碳资产托管的企业数（单位：家）',left: '5%',
          subtextStyle:{ fontSize: 20, color:'#ccd0f2'}
        },
        xAxis: {
            type: 'category', boundaryGap: true,
            data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
          //  min:1,
            minInterval:1,
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            name: '企业数', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: data
          },
        ]
			})
    },
    bar3(year=[],
      carbonEmission = [],
      CCER = [],
      carbonQuota = [],
      target = []){

      let bar3Chart = echarts.init(this.$refs.bar3);
      common.moveEcharts(year.length,bar3Chart)
			bar3Chart.setOption({
        color:['#f29149','#00ffcc','#00ccff','#ffff00'],
        grid: {
          left: '5%',right: '3%', bottom: '20%', top:'18%'
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          }
        },
        legend: {
          right: 40,top:0,
          textStyle: { color: "#fff",fontSize: 20 },itemGap: 30
        },
        title: {
          subtext: '单位：万吨',left: '4%',
          subtextStyle:{ fontSize: 20, color:'#ccd0f2'}
        },
        xAxis: {
          type: 'category', 
          axisLine: {
            lineStyle: { color: '#3570ff' }
          },
          axisLabel: {  color:'#fff',fontSize: 20, lineHeight:40},
          data: year//['2010','2020','2030','2040','2050','2060'],
        },
        yAxis: {
          minInterval:1,
          type: 'value',
          axisLine: {
            show:true,
            lineStyle: {
              color: '#3570ff'
            }
          },
          splitLine: { show: false},
          // axisLabel: { show:false}
        },
        series: [{
            type: 'bar',barWidth: '16%', name:'碳排放',
            data:carbonEmission// [270,280,350,450,330,340]
          },
          {
            type: 'bar',barWidth: '16%', name:'CCER', stack: 'total', 
            data: CCER//[150,90,160,200,180,230]
          },
          {
            type: 'bar',barWidth: '16%', name:'碳配额', stack: 'total', 
            data:carbonQuota// [50,80,160,200,160,200]
          },
          {
            type: 'bar',barWidth: '16%', name:'配额', stack: 'total', 
            data:target //[60,50,40,0,0,0]
          }
        ]
			})
    },
    line2(year=[],emission=[],CCER=[]){
      let line2Chart = echarts.init(this.$refs.line2);

      common.moveEcharts(emission.length,line2Chart)
  
			line2Chart.setOption({
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
        },
        color:['#00ffcc','#ffff00'],
        grid: {
            left: '6%',right: '4%', bottom: '18%', top:'12%'
        },
        title: {
          subtext: '成交价（预案）',left: '5%',
          subtextStyle:{ fontSize: 20, color:'#ccd0f2'}
        },
        legend: {
            right: 40,top:0,
            textStyle: { color: "#fff",fontSize: 20 },itemGap: 30
        },
        xAxis: {
            type: 'category', boundaryGap: true,
            data: year,
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
          minInterval:1,
            type: 'value', 
            // max:300,
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            // axisLabel: { show:false}
        },
        series: [{
            name: '配额', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: emission
          },{
            name: 'CCER', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: CCER
          },
        ]
			})
    },
    pie6(name,data1,text){
      let pie6Chart = echarts.init(this.$refs[name]);
      
			pie6Chart.setOption({

        color:['#00ffcc','#eb6098','#ffff00','#f29149','#b486f3'],

        title:{
          text:text, left:'20%', top:'5%',
          textStyle:{ fontSize:24,color:'#00ffcc', fontWeight:500}
        },
        legend: {
          orient: 'vertical', icon: "circle",align: 'left',
          right: '6%',top:'center',itemGap:30, itemHeight:18,itemWidth:18,
          textStyle: {
            color: "#fff",fontSize:20, lineHeight:18
          },
          formatter: function(name) {
            var value='';
            var sum=0;
            for (var i = 0, l = data1.length; i < l; i++) {
              sum+=data1[i].value;
              if (data1[i].name == name) {
                  value = data1[i].value;
              }
            }
            let num= (value/sum*100).toFixed(0)
            num = isNaN(num)?'':num
            return name +''+ value +' '+ num+'%';
          },
        },
				series: [
          {
            type: 'pie', name:'11',
            radius: ['30%', '50%'],
            center:['30%', '50%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: false
            },
            data: data1
          }
        ]
			})
    },



  },
  mounted() {
    this.myfun(0,0);
    this.mypie('pie2',0,'#f29149');
    this.mypie('pie3',0,'#00ccff');
    // this.bar1();
    // this.bar2();
    this.pie4();
    this.pie5();
    // this.line1();
    // this.bar3();
    // this.line2();

    // this.pie6('pie6',this.data1,'碳交易量（万吨）');
    // this.pie6('pie7',this.data2,'碳交易额（万元）');



 


    //全市企业占比分析
    api.getCompanyDevCount().then(res=>{
      if (res.code===10000) {
        this.CompanyDevCount = res.data
        var payCompany = this.CompanyDevCount.payCompany /this.CompanyDevCount.company *100 //已完成清缴的企业
        var quotaCompany = this.CompanyDevCount.quotaCompany /this.CompanyDevCount.company *100 //已完成清缴的企业
        this.myfun(parseInt(payCompany),parseInt(quotaCompany));
      } else {
        this.$message.error(res.message)
      }
    })
    //全市绿证占比分析
    api.greenCertificate().then(res=>{
      if (res.code===10000) {
        this.Certificate = res.data
        // 同比计算
        this.Certificate.ccerProValueTB = (this.Certificate.ccerProValue - this.Certificate.ccerProValueLast) / this.Certificate.ccerProValueLast * 100
        this.Certificate.ccerTotalTB = (this.Certificate.ccerTotal - this.Certificate.ccerTotalLast) / this.Certificate.ccerTotalLast * 100
        this.Certificate.ccerProValueTB = isNaN(this.Certificate.ccerProValueTB)?0: this.Certificate.ccerProValueTB.toFixed(2)
        this.Certificate.ccerTotalTB = isNaN(this.Certificate.ccerTotalTB)?0: this.Certificate.ccerTotalTB.toFixed(2)
        //画图
        //PV：光伏 waterPower:光电 forestry:林业碳汇 regenerate：再生生物
        this.pie4(this.Certificate.regenerate,this.Certificate.forestry,this.Certificate.waterPower,this.Certificate.pv)
      } else {
        this.$message.error(res.message)
      }
    })
    //各地区碳配额占比分析
    api.quotaProportion().then(res=>{
      if (res.code===10000) {
        //占比图
        var total = 0
        var used = 0
        var area=[]
        var data=[]
        var target=[]
        for (const iterator of res.data) {
          total+=iterator.data
          used+=iterator.target
          area.push(iterator.area)
          data.push(iterator.data)
          target.push(iterator.target)
        }
        this.quotaProportion.total = total
        this.quotaProportion.used = used
        var percent = parseInt(used/total*100)
        percent = isNaN(percent)?0:percent
        this.mypie('pie2',percent,'#f29149');
        //柱状图
        this.bar1(area,target,data)

      } else {
        this.$message.error(res.message)
      }
    })
    //各行业碳配额占比分析
    api.tradeProportion().then(res=>{
      if (res.code===10000) {
        //占比图
        var total = 0
        var used = 0
        var area=[]
        var data=[]
        var target=[]
        for (const iterator of res.data) {
          total+=iterator.data
          used+=iterator.target
          area.push(iterator.area)
          data.push(iterator.data)
          target.push(iterator.target)
        }
        this.tradeProportion.total = total
        this.tradeProportion.used = used
        var percent = parseInt(used/total*100)
        percent = isNaN(percent)?0:percent
        this.mypie('pie3',percent,'#00ccff');
        //柱状图
        this.bar2(area,target,data)

        // //自动显示
        // if (this.bar2Interval) {
        //   clearInterval(this.bar2Interval)
        // }
        // this.bar2Interval = setInterval(()=>{
        //   let dataLen = area.length;
        //   // 取消之前高亮的图形
        //   this.bar2Chart.dispatchAction({
        //     type: 'downplay',
        //     seriesIndex: 0,
        //     dataIndex: this.bar2Index
        //   });
        //   this.bar2Index = (this.bar2Index + 1) % dataLen;
        //   // 高亮当前图形
        //   this.bar2Chart.dispatchAction({
        //     type: 'highlight',
        //     seriesIndex: 0,
        //     dataIndex: this.bar2Index
        //   });
        //   // 显示 tooltip
        //   this.bar2Chart.dispatchAction({
        //     type: 'showTip',
        //     seriesIndex: 0,
        //     dataIndex: this.bar2Index
        //   });
        // },2000);




      } else {
        this.$message.error(res.message)
      }
    })
    //全市碳资产管理企业占比分析
    api.proportionOfCarbon().then(res=>{
      if (res.code===10000) {
        //饼图
        var data = res.data
        this.pie5(data.newEnergy,data.energy)
        //折线图
        var table = []
        for (const iterator of data.table) {
         table.push(iterator.data);
        }
        this.line1(table)
      } else {
        this.$message.error(res.message)
      }
    })
    //重点能耗企业控排指标排名
    api.pointCompanyTarget().then(res=>{
      if (res.code===10000) {
        this.pointCompanyTarget = res.data
        this.pointCompanyTarget.targetTotalArr =  this.pointCompanyTarget.targetTotal.toString().split()
      } else {
        this.$message.error(res.message)
      }
    })
    //碳达峰碳中和战略方向
    api.strategicDirection().then(res=>{
      if (res.code===10000) {
        var year = []
        var carbonEmission = []//碳排放
        var CCER = []
        var carbonQuota = []//碳配额
        var target = []//配额
        for (const iterator of res.data) {
          year.push(iterator.year+'年')
          carbonEmission.push(iterator.carbonEmission)
          CCER.push(iterator.CCER)
          carbonQuota.push(iterator.carbonQuota)
          target.push(iterator.target)
        }
        this.bar3(year, carbonEmission, CCER, carbonQuota, target)

      } else {
        this.$message.error(res.message)
      }
    })
    //各地区近年来碳市场单价分析
    api.carbonMarket().then(res=>{
      if (res.code===10000) {
        var year = []
        var emission = []//碳排放
        var CCER = []
        for (const iterator of res.data) {
          year.push(iterator.year+'年')
          emission.push(iterator.emission)
          CCER.push(iterator.CCER)
        }
        this.line2(year,emission,CCER)
      } else {
        this.$message.error(res.message)
      }
    })
    //各地区碳市场交易占比

    api.shareOfTrading().then(res=>{
      if (res.code===10000) {

        this.data1 = []
        this.data2 = []
        for (const iterator of res.data) {
          this.data1.push({
            name:iterator.area,
            value:iterator.turnover
          })
          this.data2.push({
            name:iterator.area,
            value:iterator.tradingVolume
          })
        }
        this.pie6('pie6',this.data1,'碳交易量（万吨）');
        this.pie6('pie7',this.data2,'碳交易额（万元）');

      } else {
        this.$message.error(res.message)
      }
    })



  },
  beforeDestroy(){
    //清除所有定时器
    let _end = setInterval(function(){},2000);
    for(let i =1;i<=_end;i++){
      clearInterval(i)
    }
  },
}
</script>

<style lang="less" scoped>
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;

  &>.left{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: left;
    .modulebox{ flex: 2; flex-shrink: 0;}
    .modulebox:nth-child(1){ flex: 1; margin-top: 0; overflow: hidden;
      .f_row{
        .f_column:nth-child(2){ width:20rem; flex:initial; flex-shrink: 0; padding:1rem;}
      }
    }
    .modulebox:nth-child(2){ 
      .f_column:nth-child(2){ margin-bottom: 1rem;}
    }
  }
  &>.right{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: right;
    .modulebox{
      flex-shrink: 0;
      overflow: hidden;
    }
    .modulebox:nth-child(1){ margin-top: 0;}
    .modulebox:nth-child(3){ flex: 2;}
  }
  .titlebox{ display: flex; flex-direction: row; align-items: center;
    .title{ flex: 1;}
    .select{ color: #00ccff; z-index: 99;
      .el-select{ width: 7rem; margin:0 1rem 0 .4rem;}
    }
  }

  .enterprisebox{ display: flex; flex-direction: row;
    .left{ padding-left: 1rem; width: 15rem; flex-shrink: 0; display: flex; align-items: center; flex-direction: row;
      .img{ width: 4.8rem; height: 4.8rem; margin-right: .6rem;
        img{ width: 100%; height: 100%;}
      }
      .text{ flex: 1; font-size: 1rem; color: #fff;
        b{ font-size: 1.6rem; font-weight: 500; margin-right: 10px; color: #ffff00;}
      }
    }
    .right{ flex: 1; display: flex; flex-direction: column;
      .top{ flex: 1; }
      .bottom{ display: flex; flex-direction: row; padding-bottom: 1rem;
        .li{ flex: 1; width: 50%; display: flex; flex-direction: row;
          .img{ width: 2.8rem; height: 2.8rem; margin-right: .6rem;
            img{ width: 100%; height: 100%;}
          }
          .text{ flex: 1; font-size: .8rem;
            b{ font-size: 1.3rem; font-weight: 500; margin-right: 10px; color: #00ccff;}
          }
        }
        .li:nth-child(2) b{ color: #00ffcc;}
      }
    }
  }
  .enterpriselist{ border: #005ae4 solid 1px; background: #081679; display: flex; flex-direction: column; height: 100%; overflow: hidden; border-radius: 10px; padding:.5rem;
    h2{ font-weight: 500; color: #fff; background: #01a7e5; border-radius: 10px; height: 1.6rem; line-height: 1.5rem; text-align: center; font-size: 1rem;}
    .enterpBOX{
      overflow: hidden;
    }
   ul{ flex: 1;
     height: 100%; display: flex; 
     flex-direction: column; 
     justify-content: space-evenly; 
     margin-top: .6rem;
      li{ font-size: .85rem; 
      text-align: center; border-radius: 10px;
      height: 2em;

       text-align: center; padding:6px 0;}
      li:nth-child(even){ background: #083190;}
    }
  }
  .region{ display: flex; flex-direction: row;
    .left{ flex: 1;}
    .right{ width: 18rem; flex-shrink: 0; display: flex; flex-direction: column;
      .text{ height: 40%; display: flex; flex-direction: column; align-items: center; justify-content: center;
        p{ font-size: .8rem;
          b{ font-size: 1.5rem; vertical-align: sub; font-weight: 500; margin-right: 10px; color: #00ccff;}
        }
        p:nth-child(2) b{ color:#b486f3 ;}
      }
    }
  }
  .industry{ display: flex; flex-direction: row;
    .left{ width: 18rem; flex-shrink: 0; display: flex; flex-direction: column;
      .text{ height: 40%; display: flex; flex-direction: column; align-items: center; justify-content: center;
        p{ font-size: .8rem;
          b{ font-size: 1.5rem; vertical-align: sub; font-weight: 500; margin-right: 10px; color: #ffff00;}
        }
        p:nth-child(2) b{ color:#00ffcc ;}
      }
    }
    .right{ flex: 1;}
  }
  .greencard{ display: flex; flex-direction: row; font-size: .8rem;
    .left{ flex: 1; display: flex; flex-direction: column; padding:.8rem 0;
      .li{ padding-left: 5rem; width: 100%; height: 50%; display: flex; flex-direction: column; align-items: center; justify-content: center;
        p{ display: flex; width: 100%; flex-direction: row; align-items: center;}
        p:nth-child(1){ margin-bottom: .5rem;
          img{ margin-right: 10px; width: 1.2rem;}
        }
        p:nth-child(2){ padding-left: 1.3rem;
          span{ flex: 1;}
          b{ font-size: 1.2rem; font-weight: 500; color: #00ffcc; margin-right: 6px;
            i{ font-weight: 600;}
          }
        }
      }
    }
    .right{ flex: 1; flex-shrink: 0;}
  }
  .carbonassets{ display: flex; flex-direction: row;
    .left{ width: 18rem; flex-shrink: 0;}
    .right{ flex: 1; }
  }
  .ranking{ display: flex; flex-direction: row; height: 100%; overflow:hidden;
    
    .left{ width: 26rem; flex-shrink: 0; display: flex; flex-direction: column; justify-content: space-evenly;
      .numbers{ display: flex; flex-direction: row; align-items: center; justify-content: center;
        span{ color: #fff;}
        .box{ margin:0 .6rem;
          label{ display: inline-block; padding:0 4px; margin-right: 8px; border: #005be3 solid 1px; background: rgba(0,0,0,.2); line-height: 2.2rem; box-shadow: 0 0 6px #00ccff; font-size: 2rem; font-weight: 600; color: #ffff00;}
        }
        span:last-child{ color: #ffff00; font-size: .8rem;}
      }
      .bottom{ display: flex; flex-direction: row; align-items: center; justify-content:space-around;
        .li{ display: flex; flex-direction: column;
          p:nth-child(1){ color: #fff; margin-bottom: 10px;}
          p:nth-child(2){ display: flex; flex-direction: row; align-items: baseline; font-size: .8rem;
            img{ width: 1.6rem; margin-right: 10px;}
            b{ font-size: 1.6rem; color: #00ffcc; margin-right: 10px; font-weight: 500;}
          }
        }
      }
    }
    .right{ flex: 1; padding:0 1rem 1rem; height: 100%; 
    overflow-y: auto;
      table{ width: 100%;  border-collapse: collapse; font-size: .8rem;overflow: hidden;
        thead{
          tr{
            td{ border-bottom: none;}
            td:nth-child(2),td:nth-child(3){ color: #ccd0f2;}
          }
        }





        // tbody{
          // height: 17em;
          // overflow: hidden;
        // }

        tr{
          height: 3em;
          td{ border-bottom: #005ae4 dashed 1px;}
          td:nth-child(2),td:nth-child(3){ color: #00ffcc;}
          b{ font-size: 1.2rem; font-weight: 500; margin-right: 10px;}
        }

        tr:last-child{
          td{ border-bottom: none;}
        }


      }
    }
  }
  .transaction{ display: flex; flex-direction: row;
    .left,.right{ flex: 1; width: 50%;}
  }



}
</style>
