//节能减排
import axios from 'axios';
//全市企业占比分析
const getCompanyDevCount = (data) => axios.get('/show/conserveReduce/getCompanyDevCount', data);
//全市绿证占比分析
const greenCertificate = (data) => axios.get('/show/conserveReduce/greenCertificate', data);
//各地区碳配额占比分析
const quotaProportion = (data) => axios.get('/show/conserveReduce/quotaProportion', data);
//各行业碳配额占比分析
const tradeProportion = (data) => axios.get('/show/conserveReduce/tradeProportion', data);
//全市碳资产管理企业占比分析
const proportionOfCarbon = (data) => axios.get('/show/conserveReduce/proportionOfCarbon', data);
//重点能耗企业控排指标排名
const pointCompanyTarget = (data) => axios.get('/show/conserveReduce/pointCompanyTarget', data);
// 碳达峰碳中和战略方向
const strategicDirection = (data) => axios.get('/show/conserveReduce/strategicDirection', data);
//碳市场单价分析
const carbonMarket = (data) => axios.get('/show/conserveReduce/carbonMarket', data);
// 各地区碳市场交易占比
const shareOfTrading = (data) => axios.get('/show/conserveReduce/shareOfTrading', data);


export default {
  getCompanyDevCount,
  greenCertificate,
  quotaProportion,
  tradeProportion,
  proportionOfCarbon,
  pointCompanyTarget,
  strategicDirection,
  shareOfTrading,
  carbonMarket,
}
